import { TrackingEvent } from '@js/common/tracking-utils'

import { Nullable } from '@f-fertighaus/applications/Next/types/utility'
import { errorLogger } from '@f-fertighaus/utils/errorLogger'

type TrafficChannel =
  | 'AdWords'
  | 'FacebookAds'
  | 'IM-Display'
  | 'Pinterest'
  | 'organic'

type Device = 'mobile' | 'tablet' | 'desktop'

export interface UserSessionData {
  converting_traffic_channel: TrafficChannel
  device: Device
  duration_sec: number
  nr_sessions: number
  page_cnt: number
  views_hlp: number
  views_house: number
  views_other: number
  views_vendor: number
}

function getDeviceType(userAgent: string): Device {
  const ua = userAgent.toLowerCase()

  const isTablet = /ipad|android(?!.*mobile)|tablet|playbook|silk/i.test(ua)
  if (isTablet) {
    return 'tablet'
  }

  const isMobile = /iphone|ipod|android.*mobile|blackberry|bb10|mini|windows\sce|palm/i.test(
    ua
  )
  if (isMobile) {
    return 'mobile'
  }

  return 'desktop'
}

function getConvertingTrafficChannel(url: string): TrafficChannel {
  function getUrlParam(name: string): Nullable<string> {
    const urlParams = new URL(url).searchParams
    return urlParams.get(name)
  }

  if (getUrlParam('gclid') || getUrlParam('gbraid') || getUrlParam('wbraid')) {
    return 'AdWords'
  }
  if (getUrlParam('fbclid')) {
    return 'FacebookAds'
  }
  if (
    (getUrlParam('utm_source') === 'facebook' &&
      getUrlParam('utm_medium') === 'cpc') ||
    getUrlParam('utm_medium') === 'ads'
  ) {
    return 'FacebookAds'
  }
  if (url.includes('.de/g/') || url.includes('.at/g/')) {
    return 'AdWords'
  }
  if (url.includes('.de/f/') || url.includes('.at/f/')) {
    return 'FacebookAds'
  }
  if (getUrlParam('utm_source') === 'im-display') {
    return 'IM-Display'
  }
  if (getUrlParam('utm_source') === 'pinterest') {
    return 'Pinterest'
  }

  return 'organic'
}

function initUserSessionData(): Omit<
  UserSessionData,
  'nr_sessions' | 'converting_traffic_channel'
> {
  return {
    device: getDeviceType(navigator.userAgent),
    duration_sec: 0,
    page_cnt: 0,
    views_hlp: 0,
    views_house: 0,
    views_other: 0,
    views_vendor: 0
  }
}

const collectPageData = (event: TrackingEvent, sessionDuration: number) => {
  const storedUserSessionData = sessionStorage.getItem('userSessionData')
  const isOngoingSession = sessionDuration && storedUserSessionData

  const userSessionData = isOngoingSession
    ? JSON.parse(storedUserSessionData)
    : initUserSessionData()

  const isViewsOther =
    event.label !== 'holistic-landing-page' &&
    event.label !== 'house-detail' &&
    event.label !== 'vendor-detail'
  const updatedSessionData = {
    ...userSessionData,
    page_cnt: userSessionData.page_cnt + 1,
    duration_sec: sessionDuration,
    views_hlp:
      event.label === 'holistic-landing-page'
        ? userSessionData.views_hlp + 1
        : userSessionData.views_hlp,
    views_house:
      event.label === 'house-detail'
        ? userSessionData.views_house + 1
        : userSessionData.views_house,
    views_vendor:
      event.label === 'vendor-detail'
        ? userSessionData.views_vendor + 1
        : userSessionData.views_vendor,
    views_other: isViewsOther
      ? userSessionData.views_other + 1
      : userSessionData.views_other
  }

  sessionStorage.setItem('userSessionData', JSON.stringify(updatedSessionData))
}

const MAX_SESSION_DURATION_SECONDS = 1800

interface Session {
  converting_traffic_channel: TrafficChannel
  nr_sessions: number
  session_start: number
}

function initSessionTracking(): Session {
  return {
    converting_traffic_channel: getConvertingTrafficChannel(
      window.location.href
    ),
    nr_sessions: 1,
    session_start: Date.now()
  }
}

const getUpdatedSessionData = () => {
  const storedSession = localStorage.getItem('userSessionData')
  const session: Session = storedSession
    ? (JSON.parse(storedSession) as Session)
    : initSessionTracking()

  const currentTime = Date.now()

  const currentSessionDurationSeconds = Math.round(
    (currentTime - session.session_start) / 1000
  )

  const isFirstSession = session.nr_sessions === 1
  const isExpiredSession =
    currentSessionDurationSeconds >= MAX_SESSION_DURATION_SECONDS

  const getSessionNumber = () => {
    if (isExpiredSession) {
      return session.nr_sessions + 1
    }
    if (isFirstSession) {
      return 1
    }
    return session.nr_sessions
  }

  const updatedSessionData = {
    ...session,
    nr_sessions: getSessionNumber(),
    session_start: isExpiredSession ? currentTime : session.session_start
  }

  return {
    updatedSessionData,
    currentSessionDurationSeconds,
    isExpiredSession
  }
}

const updateUserSessionData = () => {
  try {
    const { updatedSessionData } = getUpdatedSessionData()

    localStorage.setItem('userSessionData', JSON.stringify(updatedSessionData))
  } catch (e) {
    errorLogger(e)
  }
}

const getUserSessionDuration = () => {
  const {
    isExpiredSession,
    currentSessionDurationSeconds
  } = getUpdatedSessionData()

  if (isExpiredSession) {
    return 0
  }

  return currentSessionDurationSeconds
}

const getUserSessionData = (): Partial<UserSessionData> => {
  const storedUserData = sessionStorage.getItem('userSessionData')
  const userData = storedUserData
    ? (JSON.parse(storedUserData) as Omit<
        UserSessionData,
        'nr_sessions' | 'converting_traffic_channel'
      >)
    : {}

  const {
    updatedSessionData,
    currentSessionDurationSeconds
  } = getUpdatedSessionData()

  const { nr_sessions, converting_traffic_channel } = updatedSessionData

  return {
    ...userData,
    converting_traffic_channel,
    nr_sessions,
    duration_sec: currentSessionDurationSeconds
  }
}

export {
  collectPageData,
  getConvertingTrafficChannel,
  getUpdatedSessionData,
  getUserSessionData,
  getUserSessionDuration,
  initUserSessionData,
  updateUserSessionData
}
